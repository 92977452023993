<template>
  <div class="login" :style="{background: pageDeploy.LoginForm ? `url(${config.baseURL + '/resource/GetFile/' + pageDeploy.LoginForm.id}) 100% 100%` : ''}">
    <!-- 头 -->
    <header>
      <div class="loginLogo" v-if="pageDeploy.Logo">
        <img :src="pageDeploy.Logo.url" alt="" class="logo">
      </div>
      <div class="loginTitle">
        <span v-if="!pageDeploy.titleType">
          {{ pageDeploy.title }}
        </span>
        <img :src="pageDeploy.LoginTitlePic.url" alt="" v-else>
      </div>
    </header>
    <!-- 主体 -->
    <div class="main" :style="{minHeight: !pageDeploy.title && !pageDeploy.LoginTitlePic ? '380px' : '300px'}">
      <div
          class="loginBox"
          :style="`
          ${pageDeploy.loginFormPosition || 'left: 50%;margin-left: -200px;'};
          backgroundImage: url(${pageDeploy.LoginBackPic ? pageDeploy.LoginBackPic.url : ''});
          backgroundSize: '100% 100%'
        `"
      >
        <h1 class="title" v-if="!pageDeploy.title && !pageDeploy.LoginTitlePic">系统登录</h1>
        <div class="loginInput">
          <Form ref="loginForm" :model="loginData" :rules="modRule" @keyup.native.enter="handleSubmit()">
            <FormItem label="账号" prop="Username" style="margin-bottom: 15px;">
              <Input type="text" v-model="loginData.Username" size="large" placeholder="请输入账号"></Input>
            </FormItem>
            <FormItem label="密码" prop="Password" style="padding-bottom: 10px;">
              <Input type="password" v-model="loginData.Password" size="large" placeholder="请输入密码"></Input>
            </FormItem>
            <Button type="primary" long @click="handleSubmit()" :loading="loading" size="large">{{ loading ? '登录中...' : ' 登 录' }}</Button>
          </Form>
        </div>
      </div>
    </div>
    <!-- footer -->
    <footer>
      <div class="loginTagging" v-html="pageDeploy.copyright || ''"></div>
    </footer>
    <Modal
        v-model="confirmLogin"
        footer-hide
        :width="500"
        @on-cancel="closeConfirm"
        title="">
      <p class="platformTitle">请选择要登录的系统</p>
      <ul class="platform">
        <li v-for="(item,index) in Object.keys(platformList).filter(item=>item !== '6')" :key="index" @click="platformChange(item)">
          <div class="icon">
            <Icon :custom="'iconfont ' + platformIcon[item]||'ios-aperture-outline'" size="35"/>
          </div>
          <p class="name">{{ parseInt(item)|getBaseDataName('平台类型') }}</p>
        </li>
      </ul>
    </Modal>
  </div>
</template>

<script>
import {GetCurrentInfo, getSystemInfo, loginAuth, refreshToken, getByDomain} from "@/api/common";
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {all} from 'ys-admin'
import config from "@/config";

const weekPassWord = ['123456', 'admin', '111111', '12345678']

export default {
  name: 'login',
  data() {
    return {
      backName: "",
      pageDeploy: {},
      confirmLogin: false,
      loading: false,
      platformList: {},
      loginData: {
        platform: '',
        Username: '',
        Password: '',
      },
      platformIcon: {
        1: 'icon-jianzhuwu',
        2: 'icon-baoxian-shi',
        3: 'icon-qiyerending-',
        4: 'icon-fuwu',
        5: 'icon-gongrenweihu',
        7: 'icon-shuili',
      },
      modRule: {
        Username: [
          {required: true, message: '请输入工号', trigger: 'blur'},
        ],
        Password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.backName = from.name;
    })
  },
  computed: {
    ...mapGetters(['config'])
  },
  created() {
    all([
      getSystemInfo(),
      getByDomain({domain: window.location.host}),
    ]).then((res) => {
      this.pageDeploy = res[1].data || {}
      let title = res[1].data?.title || res[0].data.insTitle || this.config.title || config.title;
      let boardTitle = res[0].data.insBoardTitle || this.config.boardTitle || '';
      let desc = res[0].data.insDesc || this.config.desc || '';
      this.setConfig({title, boardTitle, desc});
      document.title = title;
    })
  },
  async mounted() {
    const {token, sign, appId, week = '0', username, timestamp} = this.$router.currentRoute.query;
    sessionStorage.setItem('weekPassWord', week);
    if (token) {
      await this.setToken(token);
      window.setTimeout(() => {
        this.getUserInfo();
      }, 300)
    } else {
      if (sign && appId && username) {
        this.loading = true;
        this.$post('/auth/LoginBySystemAppId', {appId, username, timestamp, sign}).then((res) => {
          if (res.code === 200) {
            this.platformList = res.data.platformsInfo || {};
            this.setToken(res.data.token);
            this.setUserInfo(res.data.user);
            const platformList = Object.keys(this.platformList).filter(item => item !== '6');
            if (platformList.length === 1) {
              this.loginData.platform = platformList[0];
              this.loginByOrgId({orgId: this.platformList[this.loginData.platform][0].id})
            }else if (platformList.length === 0) {
              this.$Notice.error({
                title: "登陆失败！",
                desc: '暂无权限登录该平台！'
              });
              this.loading = false;
            } else {
              this.confirmLogin = true;
            }
          } else {
            this.loading = false;
          }
        })
      }
    }
  },
  methods: {
    ...mapActions(['getMenuList', 'getConfigInfo']),
    ...mapMutations(['setToken', 'setUserInfo', 'setConfig']),
    platformChange(index) {
      this.loginData.platform = parseInt(index);
      this.loginByOrgId({orgId: this.platformList[this.loginData.platform][0].id})
    },
    getUserInfo() {
      GetCurrentInfo().then(info => {
        /*当前登录账户和前登录账户不一致，清除本地缓存*/
        let userInfo = {};
        userInfo = info.data;
        if (localStorage.getItem('userId') !== String(userInfo.id)) {
          localStorage.removeItem('pageTabList');
          localStorage.removeItem('set');
          this.backName = '';
        }
        this.setUserInfo(userInfo);
        this.getMenuList();
        this.getConfigInfo();
        this.confirmLogin = false;
        this.$router.push({
          name: this.backName || this.config.default
        })
      });
    },
    closeConfirm() {
      this.setToken();
      this.setUserInfo();
      sessionStorage.clear();
      this.loading = false;
    },
    loginByOrgId(params) {
      this.loading = true;
      refreshToken(params).then((res) => {
        const {host, token} = res.data;
        if (res.code === 200) {
          if (host && location.origin !== host && (location.hostname !== 'localhost' && location.hostname !== '192.168.1.97')) {
            sessionStorage.clear();
            window.location.href = host + '/#/login?token=' + token + '&week=' + String(weekPassWord.indexOf(this.loginData.Password) >= 0);
            return false;
          } else {
            sessionStorage.setItem('weekPassWord', String(weekPassWord.indexOf(this.loginData.Password) >= 0));
            this.setToken(res.data.token);
            window.setTimeout(() => {
              this.getUserInfo();
            }, 300);
          }
        }
      }).catch(() => {
        this.loading = false;
      });

    },
    handleSubmit() {
      this.$refs['loginForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          loginAuth(this.loginData).then((res) => {
            if (res.code === 200) {
              this.platformList = res.data.platformsInfo || {};
              this.setToken(res.data.token);
              this.setUserInfo(res.data.user);
              const platformList = Object.keys(this.platformList).filter(item => item !== '6');
              if (platformList.length === 1) {
                this.loginData.platform = platformList[0];
                this.loginByOrgId({orgId: this.platformList[this.loginData.platform][0].id})
              } else if (platformList.length === 0) {
                this.$Notice.error({
                  title: "登陆失败！",
                  desc: '暂无权限登录该平台！'
                });
                this.loading = false;
              } else {
                this.confirmLogin = true;
              }
            } else {
              this.loading = false;
            }
          }).catch(() => {
            this.loginData.Password = "";
            this.loading = false;
          });
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
@import "../../assets/font/iconfont.css";

.login {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url("../../assets/images/loginbg.jpg") 100% 100%;

  header {
    width: 1000px;
    margin: 0 auto;
    padding: 50px 0;

    .loginLogo {
      text-align: center;

      .logo {
        max-height: 100px;
        max-width: 250px;
      }
    }

    .loginTitle {
      margin-top: 40px;
      text-align: center;

      span {
        font-size: 40px;
        background: linear-gradient(179deg, #ffffff 36%, #addff6 100%);
        -webkit-background-clip: text;
        color: transparent; // 字体透明
      }
    }
  }

  .main {
    width: 100%;
    min-height: 300px;
    margin: 0 auto;
    position: relative;

    .loginBox {
      position: absolute;
      z-index: 999;
      width: 400px;
      padding: 10px 20px 40px;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.3) 0 0 5px;

      .title {
        text-align: center;
        font-size: 24px;
        padding-top: 32px;
        font-weight: 400;
        padding-bottom: 40px;
      }
    }
  }

  footer {
    width: 1000px;
    min-height: 120px;
    margin: 0 auto;
    position: relative;

    .loginTagging {
      width: 100%;
      position: absolute;
      bottom: 0;
      text-align: center;
      color: #ffffff;
      font-size: 15px;
      line-height: 30px;
    }
  }
}

</style>
